import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLocalOrder, syncOrderWithServer } from '../Redux/Slice';


const useCreateOrder = () => {
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();

  const createOrder = async (userId, comercioId) => {
    setIsCreating(true);

    const tempOrderId = `temp-${Date.now()}`;

    dispatch(addLocalOrder({
      id: tempOrderId,
      user: { id: userId },
      comercio: { id: comercioId },
      estado: "xxxx",
      total: 0,
      pedido_articulos: []
    }));

    try {
      const response = await fetch("https://back-deternera.onrender.com/api/pedidos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          data: {
            user: { id: userId },
            comercio: { id: comercioId },
            estado: "xxxx",
            total: 0
          }
        }),
        credentials: "include"
      });

      if (!response.ok) {
        throw new Error('Failed to create order');
      }

      const data = await response.json();
      
      dispatch(syncOrderWithServer({
        tempId: tempOrderId,
        realOrder: data.data
      }));

    } catch (error) {
      console.error('Error creating order:', error);
      // Manejar el error aquí (por ejemplo, mostrar una notificación)
    } finally {
      setIsCreating(false);
    }
  };

  return { createOrder, isCreating };
};

export default useCreateOrder;