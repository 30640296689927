import React from 'react';
import { Button, Box, Image } from '@chakra-ui/react';
import Gmail from '../../assets/Glogo.png';

const GoogleButton = () => {
  const handleGoogleLogin = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_GOOGLE_CALLBACK_URL);
    const scope = encodeURIComponent('email profile');
    
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
    
    console.log('Google Auth URL:', googleAuthUrl); // Para depuración
    window.location.href = googleAuthUrl;
  };

  return (
  <Button
      onClick={handleGoogleLogin}
      colorScheme="blue"
      size="lg"
      width="full"
      borderRadius="24px"
      maxW="500px"
      margin="auto"
    >
      Iniciar sesión con Google 
      <Box borderRadius="full" bg="white" p="1" ml="2">
        <Image src={Gmail} alt="Logo Google auth" boxSize="24px" />
      </Box> 
    </Button>
  );
};

export default GoogleButton;