import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button } from "@chakra-ui/react";
import BlackBox from "../Landing/InfoTopBox";
import { updateUserProfile } from "../Redux/Slice";


const PersonalDataForm = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    username: "",
    apellido: "",
    direccion: "",
    telefono: "",
    email: ""
  });

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || "",
        apellido: user.apellido || "",
        direccion: user.direccion || "",
        telefono: user.telefono || "",
        email: user.email || ""
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserProfile({ userId: user.id, userData: formData }));
  };

  return (
    <div className="flex items-center mb-6" style={{margin:"1rem"}}>
      <BlackBox
        showBackButton={true}
        titulo="Datos personales"
        info="Estos son los datos que usaremos a la hora de facturar y enviar tu pedido"
      />

      <form onSubmit={handleSubmit} className="space-y-4" style={{ display: "flex", flexDirection: "column", gap:"1rem", marginTop:"1rem" }}>
        <label htmlFor="username" style={styles.label}>Nombre</label>
        <Input name="username" type="text" style={styles.input} value={formData.username} onChange={handleChange} />
        
        <label htmlFor="apellido" style={styles.label}>Apellido</label>
        <Input name="apellido" type="text" style={styles.input} value={formData.apellido} onChange={handleChange} />
        
        <label htmlFor="direccion" style={styles.label}>Dirección de entrega</label>
        <Input name="direccion" type="text" style={styles.input} value={formData.direccion} onChange={handleChange} />
        
        <label htmlFor="telefono" style={styles.label}>Teléfono</label>
        <Input name="telefono" type="text" style={styles.input} value={formData.telefono} onChange={handleChange} />
        
        <label htmlFor="email" style={styles.label}>Correo electrónico</label>
        <Input name="email" type="email" style={styles.input} value={formData.email} onChange={handleChange} />

        <Button type="submit" colorScheme="blue">Guardar cambios</Button>
      </form>
    </div>
  );
};

export default PersonalDataForm;

const styles = {
  label: {
    fontWeight: "bold",
    textAlign: "left",
    paddingLeft: ".5rem"
  },
  input: {
    backgroundColor: "#F2F2F2",
    borderRadius: "12px",
    padding: ".5rem",
    paddingLeft: "1rem",
    fontWeight: "500"
  }
};