import React, { useEffect, useRef, useState } from "react";
import { Box, Text, VStack, Flex, ChakraProvider, Button, Textarea, useToast } from "@chakra-ui/react";
import BlackBox from "../Landing/InfoTopBox";
import BagCard from "./BagCard";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Carousel from "../Landing/MasVendidos";
import { updateCartQuantity, syncCartWithServer, finalizarPedido, clearCart, pedidoPendiente } from "../Redux/Slice";
import useCreateOrder from "../Hooks/useCreateOrder";


export default function Carrito() {
  const scrollRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [detalle, setDetalle] = useState("");
  const { pedidoActual, articulos, valores, user } = useSelector(state => state);
  const { createOrder, isCreating } = useCreateOrder();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleQuantityChange = (articleId, valorId, quantity) => {
    dispatch(updateCartQuantity({ articleId, valorId, quantity }));
  };

  const getArticuloNombre = (id) => {
    const foundArticulo = articulos.find(art => art.id === id);
    return foundArticulo ? foundArticulo.nombre : 'Artículo desconocido';
  };

  const getValorNombre = (id) => {
    const foundValor = valores.find(val => val.id === id);
    return foundValor ? foundValor.attributes.nombre : 'Valor desconocido';
  };

  const createWhatsAppMessage = () => {
    let message = `¡Hola! He realizado un nuevo pedido: Número #${pedidoActual?.id}\n\n`;
    pedidoActual?.attributes?.pedido_articulos.forEach((product, index) => {
      const articuloNombre = getArticuloNombre(product?.articleId);
      const valorNombre = getValorNombre(product?.valorId);
      message += `${index + 1}. ${articuloNombre} - ${valorNombre}\n`;
      message += `   Cantidad: ${product.cantidad}, Precio unitario: $${product?.precioFinal}\n`;
    });
    message += `\nTotal del pedido: $${pedidoActual?.attributes?.total}`;
    message += `\n\nNúmero de pedido: #${pedidoActual?.id}`;
    if (detalle) {
      message += `\n\nDetalles adicionales: ${detalle}`;
    }
    return message;
  };

  const handleFinalizarPedido = async () => {
    if (!pedidoActual || !pedidoActual.attributes || pedidoActual.attributes.pedido_articulos.length === 0) {
      toast({
        title: "Carrito vacío",
        description: "No se puede finalizar el pedido porque el carrito está vacío.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    let pedidoId = pedidoActual.id;
  
    try {
      // Si no hay ID de pedido o es temporal, crear uno nuevo
      if (!pedidoId || (typeof pedidoId === 'string' && pedidoId.startsWith('temp-'))) {
        const newOrder = await createOrder(user.id, 1);
        pedidoId = newOrder.id;
      }
  
      // Iniciar la sincronización y finalización del pedido en segundo plano
      const finalizePedidoPromise = (async () => {
        await dispatch(syncCartWithServer()).unwrap();
        return dispatch(pedidoPendiente({ pedidoId, detalle })).unwrap();
      })();
  
      // Preparar el mensaje de WhatsApp
      const phoneNumber = "2915729501";
      const message = createWhatsAppMessage();
      
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isAndroid = /android/i.test(userAgent);
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  
      let whatsappUrl;
      if (isAndroid) {
        whatsappUrl = `intent://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}#Intent;scheme=whatsapp;package=com.whatsapp;end`;
      } else if (isIOS) {
        whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      } else {
        whatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      }
  
      // Realizar las redirecciones inmediatamente
      window.open(whatsappUrl, '_blank');
      navigate(`/Exito/${pedidoId}`);
  
      // Mostrar toast de éxito
      toast({
        title: "Pedido finalizado",
        description: "Tu pedido ha sido enviado correctamente.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      // Esperar a que se complete la finalización del pedido en segundo plano
      await finalizePedidoPromise;
  
      // Limpiar el carrito después de que todo se haya completado
      dispatch(clearCart());
  
    } catch (error) {
      console.error('Error al finalizar el pedido:', error);
      toast({
        title: "Error",
        description: "Hubo un problema al finalizar el pedido. Por favor, inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // Considerar redirigir al usuario de vuelta al carrito si hay un error
      navigate('/carrito');
    }
  };
  return (
    <ChakraProvider>
      <Box position="relative" minHeight="100vh">
        <VStack spacing={4} p={"12px"} pb="240px">
          <BlackBox
            titulo={`Mi carrito`}
            info="Revisá que estén todos los productos que estás buscando"
            showBackButton={true}
          />
          <VStack w="100%" spacing={0}>
            {pedidoActual?.attributes?.pedido_articulos && pedidoActual.attributes.pedido_articulos.length > 0 ? (
              pedidoActual.attributes.pedido_articulos.map((product, index) => (
                <BagCard
                  key={index}
                  producto={product}
                  onQuantityChange={handleQuantityChange}
                />
              ))
            ) : (
              <Text>El carrito está vacío</Text>
            )}
          </VStack>
          <Textarea
            placeholder="Agrega detalles adicionales (máximo 150 palabras)"
            value={detalle}
            onChange={(e) => setDetalle(e.target.value)}
            maxLength={150}
            resize="vertical"
          />
          <Carousel />
        </VStack>
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bg="white"
          p={4}
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1)"
        >
          <Flex justifyContent="flex-start" alignItems="center" gap=".4rem">
            <Text fontSize="24px" fontWeight="bold">Total:</Text>
            <Text fontSize="24px" fontWeight="bold">$</Text>
            <Text fontSize="24px" fontWeight="bold">
              {pedidoActual?.attributes?.total != null 
                ? Number(pedidoActual.attributes.total).toFixed(2) 
                : '0.00'}
            </Text>
          </Flex>
          <Button
            w="100%"
            borderRadius={"24px"}
            bgColor="#CA0017"
            color="white"
            fontSize="1rem"
            height="3rem"
            onClick={handleFinalizarPedido}
            id="finalizarCompraCarrito"
            isLoading={isCreating}
            disabled={!pedidoActual?.attributes?.pedido_articulos || pedidoActual.attributes.pedido_articulos.length === 0}
          >
            Finalizar y Contactar por WhatsApp
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}