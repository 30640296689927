import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './Slice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Cambiamos a localStorage

const EXPIRATION_TIME = 5 * 60 * 60 * 1000; // 5 horas en milisegundos

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    {
      in: (state) => {
        return {
          ...state,
          _timestamp: Date.now(),
        };
      },
      out: (state) => {
        const now = Date.now();
        const storedTime = state._timestamp;

        if (now - storedTime > EXPIRATION_TIME) {
          // Si ha expirado, retornamos un estado vacío o inicial
          return undefined;
        }
        
        // Si no ha expirado, eliminamos el timestamp y retornamos el estado
        const { _timestamp, ...rest } = state;
        return rest;
      },
    },
  ],
};

const persistedReducer = persistReducer(persistConfig, counterReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);