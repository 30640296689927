import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmEmail } from '../../Redux/Slice';


const EmailConfirmation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const confirmationStatus = useSelector(state => state.user.confirmationStatus);

  useEffect(() => {
    const confirmToken = new URLSearchParams(location.search).get('confirmation');
    if (confirmToken) {
      dispatch(confirmEmail(confirmToken))
        .unwrap()
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    } else {
      setError('No confirmation token found');
      setIsLoading(false);
    }
  }, [dispatch, location]);

  if (isLoading) {
    return <div>Confirmando tu email...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (confirmationStatus === 'success') {
    setTimeout(() => navigate('/login'), 3000); // Redirige al login después de 3 segundos
    return <div>¡Tu email ha sido confirmado! Serás redirigido al login en unos segundos.</div>;
  }

  return <div>Algo salió mal. Por favor, intenta registrarte de nuevo.</div>;
};

export default EmailConfirmation;