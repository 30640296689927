import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Button, Flex, Text, VStack, Box, Spinner, useMediaQuery, Image, OrderedList, ListItem } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';

const MobileAppBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalling, setIsInstalling] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isIOS, setIsIOS] = useState(false);
  const [showIOSInstructions, setShowIOSInstructions] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    
    window.addEventListener('beforeinstallprompt', handler);

    // Check if the app is already installed
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }

    // Check if the device is running iOS
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

    const timer = setTimeout(() => {
      if (!localStorage.getItem('bannerShown') && !isInstalled && isMobile) {
        setIsOpen(true);
        localStorage.setItem('bannerShown', 'true');
      }
    }, 5000);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
      clearTimeout(timer);
    };
  }, [isMobile]);

  const handleInstall = async () => {
    if (isIOS) {
      setShowIOSInstructions(true);
    } else if (deferredPrompt) {
      setIsInstalling(true);
      try {
        // Clear local storage before installation
        localStorage.clear();
        
        // Prompt user to install
        await deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      } catch (error) {
        console.error('Error during PWA installation:', error);
      } finally {
        setDeferredPrompt(null);
        setIsInstalling(false);
        setIsOpen(false);
      }
    }
  };

  const MarcaAgua = (
    <svg width="80" height="80" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.47874 33.11L13.612 17.8813C9.55277 19.9764 8.10148 16.3536 7.88324 14.2804H14.2667L18.6861 11.8252L31.6167 19.6818L29.8162 24.2648H23.2691C22.8454 25.2181 21.9317 32.0979 30.3507 35.0239" stroke="#CA0017" strokeOpacity="0.13" strokeWidth="2.365"/>
      <path d="M26.04 12.7755L24.2282 11.5677L26.3933 11.3357L29.1431 11.0411L30.0154 10.9476V11.825C30.0154 12.1396 29.9522 12.614 29.7388 13.0503C29.5171 13.5036 29.077 14.0071 28.3234 14.0825L28.0426 14.1106L27.8077 13.954L26.04 12.7755Z" stroke="#CA0017" strokeOpacity="0.13" strokeWidth="1.57666"/>
      <circle cx="19.7083" cy="19.7083" r="18.5258" stroke="#CA0017" strokeOpacity="0.13" strokeWidth="2.365"/>
    </svg>
  );
  const ShareIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <polyline points="16 6 12 2 8 6" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="12" y1="2" x2="12" y2="15" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const AddToHomeIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="18" height="18" rx="2" stroke="#000000" strokeWidth="2"/>
      <line x1="12" y1="8" x2="12" y2="16" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
      <line x1="8" y1="12" x2="16" y2="12" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
  if (!isOpen || isInstalled || !isMobile) return null;

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent border="#CA0017 solid 2px" position="relative" overflow="hidden">
        <Box position="absolute" top="50%" left="12%" transform="translate(-50%, -50%)" zIndex={0}>
          {MarcaAgua}
        </Box>
        <Box position="absolute" top="50%" right="-10%" transform="translate(-50%, -50%)" zIndex={0}>
          {MarcaAgua}
        </Box>
        <ModalCloseButton zIndex={2} />
        <ModalBody zIndex={1} position="relative">
          <VStack padding=".5rem" borderRadius="24px" m={4}>
            {!showIOSInstructions ? (
              <>
                <div>
                  <Text fontWeight="bold">¿Quieres una mejor experiencia?</Text>
                  <Text fontWeight="bold">Instala nuestra app DeTernera</Text>
                </div>
                <Flex justifyContent="space-between" gap="2rem">
                  <Button
                    color="white"
                    onClick={handleInstall}
                    bg="#CA0017"
                    borderRadius="24px"
                    disabled={isInstalling}
                  >
                    {isInstalling ? (
                      <>
                        <Spinner size="sm" mr={2} />
                        Instalando...
                      </>
                    ) : isIOS ? (
                      'Agregar a la pantalla de inicio'
                    ) : (
                      'Instalar App'
                    )}
                  </Button>
                </Flex>
              </>
            ) : (
              <VStack spacing={4} align="stretch">
              <Text fontWeight="bold">Para agregar DeTernera a tu pantalla de inicio:</Text>
              <OrderedList spacing={3}>
                <ListItem>
                  <Flex alignItems="center">
                    <Text>Toca el icono de compartir en la barra de Safari</Text>
                    <Box ml={2}><ShareIcon /></Box>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex alignItems="center">
                    <Text>Desplázate hacia abajo y toca "Agregar a la pantalla de inicio"</Text>
                    <Box ml={2}><AddToHomeIcon /></Box>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Text>Toca "Agregar" en la esquina superior derecha</Text>
                </ListItem>
              </OrderedList>
              <Text fontSize="sm" fontStyle="italic">
                Esto creará un icono de DeTernera en tu pantalla de inicio para acceso rápido.
              </Text>
              <Button onClick={() => setIsOpen(false)} colorScheme="red">
                Entendido
              </Button>
            </VStack>
          )}
        </VStack>
      </ModalBody>
    </ModalContent>
  </Modal>
  );
};

export default MobileAppBanner;