import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE = process.env.REACT_APP_API_BASE;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const initialState = {
  user: null,
  token: null,
  role: null,
  categories: [],
  articulos: [],
  valores: [],
  cart: [],
  pedidos: [],
  pedidoActual: {
    id: null,
    attributes: {
      pedido_articulos: [],
      total: 0,
    },
  },
  currentOrder: null,
  orders: [],
  historial: [],
  cartTotal: 0,
  status: "idle",
  error: null,
  pedidoFinalizado: false,
};

// Async thunks
export const fetchCategories = createAsyncThunk(
  "counter/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE}/api/categorias?populate=img&populate=sub_categorias.articulos.valors&populate=sub_categorias.articulos.img&populate=sub_categorias.articulos.insignias`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPedidosUser = createAsyncThunk(
  "counter/fetchPedidos",
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_BASE}/api/pedidos-with-user?userId=${userId}`
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchValor = createAsyncThunk(
  "counter/fetchValor",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE}/api/valors`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_BASE}/api/auth/local/register`,
        userData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (credentials, thunkAPI) => {
    try {
      const loginResponse = await axios.post(`${API_BASE}/api/auth/local`, {
        identifier: credentials.email,
        password: credentials.password,
      });

      const { jwt: token, user } = loginResponse.data;
      setAuthToken(token);

      const userResponse = await axios.get(
        `${API_BASE}/api/users/me?populate=role&populate=pedidos`
      );

      const userData = userResponse.data;
      const role = userData.role ? userData.role.name : null;
      const pedidos = userData.pedidos || [];

      return {
        ...userData,
        token,
        role,
        pedidos,
      };
    } catch (error) {
      console.error("Login error:", error.response?.data || error.message);
      return thunkAPI.rejectWithValue(
        error.response?.data?.error?.message || error.message
      );
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateProfile",
  async ({ userId, userData }, thunkAPI) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.put(
        `${API_BASE}/api/users/${userId}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "user/confirmEmail",
  async (confirmationToken, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_BASE}/api/auth/email-confirmation`,
        {
          params: { confirmation: confirmationToken },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk("user/logout", async () => {
  setAuthToken(null);
  return null;
});

export const handleGoogleCallback = createAsyncThunk(
  "user/handleGoogleCallback",
  async (code, thunkAPI) => {
    try {
      const authResponse = await axios.post(
        `${API_BASE}/strapi-google-auth/user-profile`,
        { code }
      );

      if (
        !authResponse.data ||
        !authResponse.data.data ||
        !authResponse.data.data.token
      ) {
        throw new Error("No se recibió un token JWT válido del servidor");
      }

      const { token, user } = authResponse.data.data;

      setAuthToken(token);

      return {
        user: user,
        token: token,
      };
    } catch (error) {
      console.error(
        "Error en handleGoogleCallback:",
        error.response ? error.response.data : error.message
      );
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// export const handleGoogleCallback = createAsyncThunk(
//   "user/handleGoogleCallback",
//   async (_, thunkAPI) => {
//     try {
//       // Get the code or token from the URL's query parameters
//       const urlParams = new URLSearchParams(window.location.search);
//       const code = urlParams.get("code");

//       if (!code) {
//         throw new Error("No code found in callback URL");
//       }

//       // Handle the callback from Google
//       const response = await axios.get(`${API_BASE}/api/auth/google/callback?code=${code}`, {
//         withCredentials: true,
//       });

//       // If the callback is successful, fetch the user data
//       if (response.data) {
//         const userResponse = await axios.get(`${API_BASE}/api/users/me?populate=role`, {
//           withCredentials: true,
//         });
//         return { user: userResponse.data };
//       } else {
//         throw new Error('Failed to authenticate with Google');
//       }
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

export const crearPedido = createAsyncThunk(
  "counter/crearPedido",
  async (_, thunkAPI) => {
    const { user, token } = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${API_BASE}/api/pedidos`,
        {
          data: {
            user: { id: user.id },
            comercio: { id: 1 },
            estado: "xxxx",
            total: 0,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const syncCartWithServer = createAsyncThunk(
  "counter/syncCartWithServer",
  async (_, thunkAPI) => {
    const { pedidoActual, token } = thunkAPI.getState();
    if (!pedidoActual || !pedidoActual.id) return;

    try {
      const response = await axios.put(
        `${API_BASE}/api/pedidos/${pedidoActual.id}`,
        {
          data: {
            pedido_articulos: pedidoActual.attributes.pedido_articulos,
            total: pedidoActual.attributes.total,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const agregarArticuloPedido = createAsyncThunk(
  "counter/agregarArticuloPedido",
  async (
    { pedidoId, articuloId, valorId, cantidad, precio, nombre, valor },
    thunkAPI
  ) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${API_BASE}/api/pedido-articulos`,
        {
          data: {
            pedido: pedidoId,
            articulo: articuloId,
            valor: valorId,
            cantidad: Number(cantidad),
            precio_unitario: Number(precio),
            subtotal: Number(cantidad) * Number(precio),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return {
        id: response.data.data.id,
        articleId: articuloId,
        name: nombre,
        price: Number(precio),
        quantity: Number(cantidad),
        valor: valor,
        valorId: valorId,
        precioFinal: Number(precio),
        subtotal: Number(cantidad) * Number(precio),
        ...response.data.data.attributes,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const finalizarPedido = createAsyncThunk(
  "counter/finalizarPedido",
  async ({ pedidoId }, thunkAPI) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "finalizado",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const pedidoPendiente = createAsyncThunk(
  "counter/finalizarPedido",
  async ({ pedidoId }, thunkAPI) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "pendiente",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const ProcesoPedidoAdmin = createAsyncThunk(
  "counter/procesoPedidoAdmin",
  async (pedidoId, thunkAPI) => {
    const { token } = thunkAPI.getState();

    try {
      await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "en_proceso",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refetch all orders after updating the order status
      await thunkAPI.dispatch(obtenerTodosPedidos());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const PedidoCompletAdmin = createAsyncThunk(
  "counter/pedidoCompletAdmin",
  async (pedidoId, thunkAPI) => {
    const { token, pedidoActual } = thunkAPI.getState();

    try {
      await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "finalizado",
            total: pedidoActual?.attributes?.total,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refetch the order history after updating the order status
      await thunkAPI.dispatch(obtenerTodosPedidos());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const PedidoCanceladoAdmin = createAsyncThunk(
  "counter/pedidoCanceladoAdmin",
  async (pedidoId, thunkAPI) => {
    const { token } = thunkAPI.getState();

    try {
      await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "cancelado",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refetch all orders after updating the order status
      await thunkAPI.dispatch(obtenerTodosPedidos());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const obtenerHistorialPedidos = createAsyncThunk(
  "counter/obtenerHistorial",
  async (_, thunkAPI) => {
    const { user, token } = thunkAPI.getState();
    try {
      let params = {
        populate: "pedido_articulos.articulo,pedido_articulos.valor,user",
      };

      // Si el usuario no es admin, filtrar por su ID
      if (user?.role !== "Admin") {
        params["filters[user][id][$eq]"] = user?.id;
      }

      const response = await axios.get(`${API_BASE}/api/pedidos`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data;
    } catch (error) {
      console.error(
        "Error en obtenerHistorialPedidos:",
        error.response?.data || error.message
      );
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const obtenerTodosPedidos = createAsyncThunk(
  "counter/obtenerTodosPedidos",
  async (_, thunkAPI) => {
    const { user, token } = thunkAPI.getState();
    let allPedidos = [];
    let currentPage = 1;
    let totalPages = 1;

    try {
      do {
        let params = {
          populate: "pedido_articulos.articulo,pedido_articulos.valor,user",
          'pagination[page]': currentPage,
          'pagination[pageSize]': 100, // Aumentamos el tamaño de página para reducir el número de solicitudes
        };

        // Si el usuario no es admin, filtrar por su ID
        if (user?.role !== "Admin") {
          params["filters[user][id][$eq]"] = user?.id;
        }

        const response = await axios.get(`${API_BASE}/api/pedidos`, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data, meta } = response.data;
        allPedidos = [...allPedidos, ...data];
        totalPages = meta.pagination.pageCount;
        currentPage++;

      } while (currentPage <= totalPages);

      console.log("Total pedidos obtenidos:", allPedidos.length);
      return allPedidos;
    } catch (error) {
      console.error("Error fetching pedidos:", error);
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);


const calculateCartTotal = (pedido_articulos) => {
  return pedido_articulos.reduce(
    (total, item) => total + Number(item.subtotal || 0),
    0
  );
};

const calculatePedidoTotal = (pedidoArticulos) => {
  return pedidoArticulos.reduce(
    (total, articulo) => total + Number(articulo.subtotal),
    0
  );
};

export const clearCart = createAction("counter/clearCart");


export const agregarAlCarrito = createAsyncThunk(
  "counter/agregarAlCarrito",
  async ({ pedidoId, articuloId, valorId, cantidad, precio, nombre, valor }, thunkAPI) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${API_BASE}/api/pedido-articulos`,
        {
          data: {
            pedido: pedidoId,
            articulo: articuloId,
            valor: valorId,
            cantidad: Number(cantidad),
            precio_unitario: Number(precio),
            subtotal: Number(cantidad) * Number(precio),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return {
        id: response.data.data.id,
        articleId: articuloId,
        name: nombre,
        price: Number(precio),
        quantity: Number(cantidad),
        valor: valor,
        valorId: valorId,
        precioFinal: Number(precio),
        subtotal: Number(cantidad) * Number(precio),
        ...response.data.data.attributes,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    user: null,
    token: null,
    role: null,
    status: "idle",
    error: null,
    pedidoActual: {
      id: null,
      attributes: {
        pedido_articulos: [],
        total: 0,
      },
    },
  },
  reducers: {
    addToCart: (state, action) => {
      if (!state.pedidoActual) {
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
      }

      const { articleId, name, price, quantity, valor, valorId, precioFinal } =
        action.payload;
      const existingItemIndex =
        state.pedidoActual.attributes.pedido_articulos.findIndex(
          (item) => item.articleId === articleId && item.valorId === valorId
        );

      if (existingItemIndex !== -1) {
        const item =
          state.pedidoActual.attributes.pedido_articulos[existingItemIndex];
        item.quantity = Number(item.quantity) + Number(quantity);
        item.subtotal = Number(item.quantity) * Number(item.precioFinal);
      } else {
        state.pedidoActual.attributes.pedido_articulos.push({
          articleId,
          name,
          price: Number(price),
          quantity: Number(quantity),
          valor,
          valorId,
          precioFinal: Number(precioFinal),
          subtotal: Number(quantity) * Number(precioFinal),
        });
      }

      state.pedidoActual.attributes.total =
        state.pedidoActual.attributes.pedido_articulos.reduce(
          (total, item) => total + Number(item.subtotal),
          0
        );
    },
    updateCartQuantity: (state, action) => {
      const { articleId, valorId, quantity } = action.payload;
      const itemIndex =
        state.pedidoActual.attributes.pedido_articulos.findIndex(
          (item) => item.articleId === articleId && item.valorId === valorId
        );

      if (itemIndex !== -1) {
        const item = state.pedidoActual.attributes.pedido_articulos[itemIndex];
        if (Number(quantity) === 0 || quantity === null) {
          // Eliminar el artículo si la cantidad es 0 o null
          state.pedidoActual.attributes.pedido_articulos.splice(itemIndex, 1);
        } else {
          item.quantity = Number(quantity);
          item.subtotal = Number(item.quantity) * Number(item.precioFinal);
          item.updatedAt = new Date().toISOString();
        }
        state.pedidoActual.attributes.total = calculateCartTotal(
          state.pedidoActual.attributes.pedido_articulos
        );
      }
    },

    removeFromCart: (state, action) => {
      const { articleId, valorId } = action.payload;
      if (state.pedidoActual) {
        state.pedidoActual.attributes.pedido_articulos =
          state.pedidoActual.attributes.pedido_articulos.filter(
            (item) =>
              !(item.articleId === articleId && item.valorId === valorId)
          );
        state.pedidoActual.attributes.total = calculateCartTotal(
          state.pedidoActual.attributes.pedido_articulos
        );
      }
    },

    addLocalOrder: (state, action) => {
      state.currentOrder = action.payload;
      state.orders.push(action.payload);
    },
    syncOrderWithServer: (state, action) => {
      const { tempId, realOrder } = action.payload;
      state.orders = state.orders.map((order) =>
        order.id === tempId ? { ...order, ...realOrder } : order
      );
      if (state.currentOrder && state.currentOrder.id === tempId) {
        state.currentOrder = { ...state.currentOrder, ...realOrder };
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(agregarAlCarrito.fulfilled, (state, action) => {
      if (!state.pedidoActual) {
        state.pedidoActual = {
          id: action.payload.pedido,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
      }
      const existingItemIndex = state.pedidoActual.attributes.pedido_articulos.findIndex(
        (item) => item.articleId === action.payload.articleId && item.valorId === action.payload.valorId
      );
  
      if (existingItemIndex !== -1) {
        state.pedidoActual.attributes.pedido_articulos[existingItemIndex] = action.payload;
      } else {
        state.pedidoActual.attributes.pedido_articulos.push(action.payload);
      }
  
      state.pedidoActual.attributes.total = calculateCartTotal(state.pedidoActual.attributes.pedido_articulos);
    })
      .addCase(clearCart, (state) => {
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
        state.cart = [];
        state.cartTotal = 0;
      })
      .addCase(obtenerTodosPedidos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(obtenerTodosPedidos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.historial = action.payload;
      })
      .addCase(obtenerTodosPedidos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(obtenerHistorialPedidos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(obtenerHistorialPedidos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.historial = action.payload;
      })
      .addCase(obtenerHistorialPedidos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(confirmEmail.pending, (state) => {
        state.status = "loading";
        state.confirmationStatus = null;
      })
      .addCase(confirmEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.confirmationStatus = "success";
        // Opcionalmente, puedes actualizar el estado del usuario aquí si el backend lo devuelve
        // state.user = action.payload.user;
        // state.token = action.payload.jwt;
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        state.status = "failed";
        state.confirmationStatus = "failed";
        state.error = action.payload;
      })
      .addCase(handleGoogleCallback.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.role = action.payload.user.role?.name;

        // Inicializar pedidoActual
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload.map((category) => ({
          id: category.id,
          ...category.attributes,
          sub_categorias: category.attributes.sub_categorias.data.map(
            (subCategory) => ({
              id: subCategory.id,
              ...subCategory.attributes,
              articulos: subCategory.attributes.articulos.data.map(
                (articulo) => ({
                  id: articulo.id,
                  ...articulo.attributes,
                })
              ),
            })
          ),
        }));

        // Extraer todos los artículos de todas las categorías y subcategorías
        state.articulos = state.categories.flatMap((category) =>
          category.sub_categorias.flatMap(
            (subCategory) => subCategory.articulos
          )
        );

        console.log("Artículos cargados:", state.articulos);
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.error("Error loading categories:", action.payload);
      })
      .addCase(syncCartWithServer.fulfilled, (state, action) => {
        // Optionally update state if server response differs from local state
        if (action.payload) {
          state.pedidoActual = action.payload;
        }
      })
      .addCase(syncCartWithServer.rejected, (state, action) => {
        // Handle sync errors, maybe revert optimistic updates or show an error message
        state.error = action.payload;
      })
      // Fetch Pedidos User
      .addCase(fetchPedidosUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPedidosUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pedidos = action.payload;
      })
      .addCase(fetchPedidosUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch Valor
      .addCase(fetchValor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchValor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.valores = action.payload;
        console.log("Valores loaded:", state.valores);
      })
      .addCase(fetchValor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.error("Error loading valores:", action.payload);
      })
      // Register User
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Login User
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.token = action.payload.token;
        state.role = action.payload.role;

        // Inicializar pedidoActual
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "An unknown error occurred";
      })
      // Logout User
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "idle";
        state.user = null;
        state.token = null;
        state.role = null;
        state.cart = [];
        state.cartTotal = 0;
        state.pedidoActual = [];
        state.historial = [];
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Crear Pedido
      .addCase(crearPedido.fulfilled, (state, action) => {
        state.pedidoActual = action.payload;
        state.pedidoActual.attributes.total = 0;
        state.pedidoActual.attributes.pedido_articulos = [];
      })
      .addCase(agregarArticuloPedido.fulfilled, (state, action) => {
        if (!state.pedidoActual) {
          state.pedidoActual = {
            id: action.payload.pedido,
            attributes: {
              pedido_articulos: [],
              total: 0,
            },
          };
        }
        
        const existingItemIndex = state.pedidoActual.attributes.pedido_articulos.findIndex(
          (item) => item.articleId === action.payload.articleId && item.valorId === action.payload.valorId
        );

        if (existingItemIndex !== -1) {
          state.pedidoActual.attributes.pedido_articulos[existingItemIndex] = action.payload;
        } else {
          state.pedidoActual.attributes.pedido_articulos.push(action.payload);
        }

        state.pedidoActual.attributes.total = calculateCartTotal(state.pedidoActual.attributes.pedido_articulos);
      })
      // Finalizar Pedido

      .addCase(finalizarPedido.pending, (state) => {
        state.pedidoFinalizado = false;
      })
      .addCase(finalizarPedido.fulfilled, (state, action) => {
        state.pedidoFinalizado = true;
        // Actualizar otros estados si es necesario
      })
      .addCase(finalizarPedido.rejected, (state, action) => {
        state.pedidoFinalizado = false;
        state.error = action.payload;
      })
      // Generic error handler
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload || action.error.message;
        }
      );
  },
});

export const {
  addToCart,
  updateCartQuantity,
  removeFromCart,
  addLocalOrder,
  syncOrderWithServer,
} = counterSlice.actions;

export default counterSlice.reducer;
