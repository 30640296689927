import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart, agregarArticuloPedido, crearPedido, syncCartWithServer } from "../Redux/Slice";
import { debounce } from 'lodash';

const AddToCartButton = ({ articulo, selectedValor, cantidad, isDisabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { user, articulos, valores,pedidoActual } = useSelector((state) => state);

  const calculateDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const getArticuloNombre = (id) => {
    const foundArticulo = articulos.find((art) => art.id === id);
    return foundArticulo ? foundArticulo.nombre : "Artículo desconocido";
  };

  const getValorNombre = (id) => {
    const foundValor = valores.find((val) => val.id === id);
    return foundValor ? foundValor.attributes.nombre : "Valor desconocido";
  };

  const debouncedHandleAddToCart = debounce(async () => {
    if (!user) {
      onOpen();
      return;
    }

    if (!selectedValor || !selectedValor.attributes) {
      toast({
        title: "Error",
        description: "Por favor, seleccione una opción válida",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      let currentPedido = pedidoActual;

      if (!currentPedido || !currentPedido.id) {
        const nuevoPedido = await dispatch(crearPedido()).unwrap();
        currentPedido = nuevoPedido;
      }

      let precioBase = articulo.precioKG;
      if (articulo.DescPorciento > 0) {
        precioBase = calculateDiscountedPrice(precioBase, articulo.DescPorciento);
      }
      const precio = precioBase * (selectedValor.attributes.GrsPorcion / 1000);

      await dispatch(agregarArticuloPedido({
        pedidoId: currentPedido.id,
        articuloId: articulo.id,
        valorId: selectedValor.id,
        cantidad,
        precio,
        nombre: getArticuloNombre(articulo.id),
        valor: getValorNombre(selectedValor.id)
      })).unwrap();

      toast({
        title: "Producto agregado",
        description: "El producto se ha agregado al carrito",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

    } catch (error) {
      console.error("Error al agregar al carrito:", error);
      toast({
        title: "Error",
        description: "No se pudo agregar el producto al carrito",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, 300);

  return (
    <>
      <Button
        rounded="none"
        w="full"
        maxW="400px"
        size="lg"
        bg="#CA0017"
        borderRadius="24px"
        color="white"
        fontSize="1rem"
        zIndex={"9999"}
        _hover={{
          transform: "translateY(2px)",
          boxShadow: "lg",
        }}
        onClick={debouncedHandleAddToCart}
        isDisabled={isDisabled}
        opacity={isDisabled ? 0.5 : 1}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        id="agregarCarrito"
      >
        Agregar al carrito
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} padding={"1rem"} top={"20%"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Iniciar sesión requerida</ModalHeader>
          <ModalBody>
            Para agregar productos al carrito o iniciar el proceso de compras,
            es necesario estar logueado.
          </ModalBody>
          <ModalFooter>
            <Button
              bgColor="#CA0017"
              color="white"
              width="100%"
              borderRadius="24px"
              height="45px"
              onClick={() => navigate("/login")}
            >
              Iniciar sesión
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddToCartButton;