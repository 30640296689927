import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Text, VStack, Flex, ChakraProvider, Button, Spinner, HStack, IconButton } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import BlackBox from "../Landing/InfoTopBox";
import { useDispatch, useSelector } from "react-redux";
import { obtenerHistorialPedidos, obtenerTodosPedidos } from "../Redux/Slice";
import OrderBox from "./OrderBox";
import { FaClipboardList, FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa'

export default function Pedidos() {
  const dispatch = useDispatch();
  const { historial, user, status, error } = useSelector(state => state);
  const [selectedStatus, setSelectedStatus] = useState("pendiente");

  const fetchPedidos = useCallback(() => {
    if (user?.role === "Admin") {
      dispatch(obtenerTodosPedidos());
    } else {
      dispatch(obtenerHistorialPedidos());
    }
  }, [dispatch, user?.role]);

  useEffect(() => {
    fetchPedidos();

    let intervalId;
    if (user?.role === "Admin") {
      intervalId = setInterval(fetchPedidos, 2 * 60 * 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [fetchPedidos, user?.role]);

  const organizedPedidos = useMemo(() => {
    const result = {
      xxxx: [],
      pendiente: [],
      en_proceso: [],
      finalizado: [],
      cancelado: []
    };

    if (Array.isArray(historial)) {
      historial.forEach(pedido => {
        if (pedido && pedido.attributes) {
          const estado = pedido.attributes.estado || 'xxxx';
          if (result[estado]) {
            result[estado].push(pedido);
          } else {
            result.xxxx.push(pedido);
          }
        }
      });

      Object.keys(result).forEach(key => {
        result[key].sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
      });
    }

    return result;
  }, [historial]);

  const filteredAndSortedPedidos = useMemo(() => {
    if (selectedStatus === "todos") {
      return Object.values(organizedPedidos).flat();
    }
    return organizedPedidos[selectedStatus] || [];
  }, [organizedPedidos, selectedStatus]);

  const handleRefresh = () => {
    fetchPedidos();
  };

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES');
  };

  return (
    <ChakraProvider>
      <Box position="relative" minHeight="100vh" pb="60px">
        <VStack spacing={4} p={4}>
          <BlackBox
            titulo="Pedidos"
            info="Seleccioná cualquiera de los pedidos para conocer el detalle del mismo."
            showInicioButton={true}
          />
          <Flex justifyContent="flex-end" p={2} width={"100%"}>
            <IconButton
              icon={<RepeatIcon />}
              onClick={handleRefresh}
              aria-label="Refresh orders"
              isLoading={status === 'loading'}
            />
          </Flex>
          {status === 'loading' && <Spinner />}
          {status === 'failed' && <Text color="red.500">Error: {error}</Text>}
          {status === 'succeeded' && filteredAndSortedPedidos.length > 0 ? (
            filteredAndSortedPedidos.map(pedido => (
              pedido && pedido.attributes && pedido.attributes.pedido_articulos ? (
                <OrderBox 
                  key={pedido.id}
                  date={formatDate(pedido.attributes.createdAt)} 
                  orderNumber={pedido.id} 
                  total={pedido.attributes.total}
                  items={pedido.attributes.pedido_articulos.data || []}
                  estado={pedido.attributes.estado}
                />
              ) : null
            ))
          ) : status === 'succeeded' ? (
            <Text>No hay pedidos disponibles para el estado seleccionado.</Text>
          ) : null}
        </VStack>

        {user?.role === "Admin" && (
          <HStack spacing={2} width="100%" style={styles.container}>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaClipboardList />} fontSize={"12px"} onClick={() => handleStatusChange("pendiente")} colorScheme={selectedStatus === "pendiente" ? "blue" : "gray"}>Pendientes</Button>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaSpinner />} fontSize={"12px"} onClick={() => handleStatusChange("en_proceso")} colorScheme={selectedStatus === "en_proceso" ? "blue" : "gray"}>En Proceso</Button>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaCheckCircle />} fontSize={"12px"} onClick={() => handleStatusChange("finalizado")} colorScheme={selectedStatus === "finalizado" ? "blue" : "gray"}>Finalizados</Button>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaTimesCircle />} fontSize={"12px"} onClick={() => handleStatusChange("cancelado")} colorScheme={selectedStatus === "cancelado" ? "blue" : "gray"}>Cancelados</Button>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaClipboardList />} fontSize={"12px"} onClick={() => handleStatusChange("todos")} colorScheme={selectedStatus === "todos" ? "blue" : "gray"}>Todos</Button>
          </HStack>
        )}
      </Box>
    </ChakraProvider>
  );
}

const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    zIndex: 1000, 
  },
};