import React, { useState } from 'react';
import { Box, Button, Text, Heading, Checkbox, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleConfirm = () => {
      if (isChecked) {
          navigate(`/`);
          alert("Has confirmado que has leído la política de privacidad.");
      // Aquí puedes manejar la redirección o cualquier otra lógica que desees ejecutar tras la confirmación
    } else {
      alert("Por favor, confirma que has leído la política de privacidad.");
    }
  };

  return (
    <Box
      p={6}
      borderWidth={1}
      borderRadius="md"
      boxShadow="md"
      maxW="3xl"
      mx="auto"
      mt={10}
      textAlign="left"
    >
      <Heading fontSize="2xl" mb={6}>Política de Privacidad</Heading>
      <VStack align="left" spacing={4}>
        <Text>
          En <strong>[Nombre de la App]</strong>, respetamos tu privacidad y estamos comprometidos a proteger los datos personales que nos proporcionas.
          Esta política de privacidad explica cómo recopilamos, usamos y protegemos tu información cuando utilizas nuestra aplicación de venta de productos cárnicos.
        </Text>

        <Text><strong>1. Información que Recopilamos</strong></Text>
        <Text>
          Recopilamos diferentes tipos de información: datos personales como tu nombre, dirección, correo electrónico y datos de uso de la aplicación.
        </Text>

        <Text><strong>2. Cómo Usamos tu Información</strong></Text>
        <Text>
          Utilizamos la información para procesar pedidos, mejorar tu experiencia de usuario, enviarte comunicaciones y garantizar la seguridad de la aplicación.
        </Text>

        <Text><strong>3. Cómo Compartimos tu Información</strong></Text>
        <Text>
          Compartimos tu información solo con proveedores de servicios y para cumplir con la ley. Nunca vendemos tu información personal.
        </Text>

        <Text><strong>4. Seguridad de la Información</strong></Text>
        <Text>
          Implementamos medidas de seguridad para proteger tu información, aunque no podemos garantizar una seguridad absoluta.
        </Text>

        <Text><strong>5. Tus Derechos</strong></Text>
        <Text>
          Tienes derecho a acceder, corregir o eliminar tus datos. Puedes contactarnos para ejercer estos derechos.
        </Text>

        <Text><strong>6. Cambios a esta Política</strong></Text>
        <Text>
          Podemos actualizar esta política de privacidad en cualquier momento. Te notificaremos si hay cambios importantes.
        </Text>

        <Text><strong>7. Contacto</strong></Text>
        <Text>
          Si tienes preguntas sobre nuestra política de privacidad, puedes contactarnos en <strong>[Email de contacto]</strong>.
        </Text>
      </VStack>

      {/* Checkbox para confirmar que el usuario ha leído la política */}
      <Checkbox mt={6} isChecked={isChecked} onChange={handleCheckboxChange}>
        He leído y acepto la política de privacidad.
      </Checkbox>

      {/* Botón para confirmar la lectura */}
      <Button
        mt={4}
        colorScheme="blue"
        onClick={handleConfirm}
        isDisabled={!isChecked} // Desactiva el botón si no está marcado el checkbox
      >
        Confirmar
      </Button>
    </Box>
  );
};

export default PrivacyPolicy;