import React, { useEffect, useState } from "react";
import { Box, Button, Text, VStack, Spinner } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import BlackBox from "../Landing/InfoTopBox";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { finalizarPedido } from "../Redux/Slice";

export default function Exito() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const pedidoFinalizado = useSelector(state => state.pedidoFinalizado);

  useEffect(() => {
    const finalizarPedidoAsync = async () => {
      try {
        await dispatch(finalizarPedido({ pedidoId: id })).unwrap();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al finalizar el pedido:", error);
        setIsLoading(false);
      }
    };

    finalizarPedidoAsync();
  }, [dispatch, id]);

  const Error = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14C11 13.7167 10.9042 13.4792 10.7125 13.2875C10.5208 13.0958 10.2833 13 10 13C9.71667 13 9.47917 13.0958 9.2875 13.2875C9.09583 13.4792 9 13.7167 9 14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15ZM9 11H11V5H9V11ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
        fill="#010101"
      />
    </svg>
  );

  return (
    <VStack textAlign="center" py={10} px={6} height="100vh" justifyContent={"space-between"}>
      <Box>
        <BlackBox
          titulo={`Pedido realizado `}
          info={`Tu número de pedido es #${id}. Si ya eres cliente, podrás ver el detalle y estado del mismo en la sección "Mis pedidos"`}
          showInicioButton={true}
        />
  
        <Text style={styles.proximo}>Próximos pasos</Text>
        <Text style={styles.text}>
          En breve empezaremos a preparar tu pedido y te enviaremos el link de
          pago con su precio final.
        </Text>

        <Box bgColor={"#F2F2F2"} borderRadius={"16px"} padding={"12px"} display="flex" alignItems="center">
          {Error}
          <Text style={styles.text2} ml={2}>
            Recordá que tenés 24h para realizar el pago o el pedido quedará
            cancelado.
          </Text>
        </Box>
      </Box>
      {isLoading ? (
        <Spinner size="xl" color="red.500" />
      ) : (
        <Button
          as={NavLink}
          to="/Pedidos"
          style={styles.buttonx}
          isDisabled={!pedidoFinalizado}
        >
          Ir a mis pedidos
        </Button>
      )}
    </VStack>
  );
}

const styles = {
  proximo: {
    display: "flex",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
  },
  text: {
    fontFamily: "Segoe UI",
    fontSize: "16px",
    textAlign: "left",
  },
  text2: {
    fontFamily: "Segoe UI",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
  },
  buttonx: {
    borderRadius: "16px",
    backgroundColor: "#CA0017",
    color: "white",
    width: "100%",
  }
};