import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGoogleCallback } from '../../Redux/Slice';

const GoogleCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    console.log("Google auth code:", code);

    if (code) {
      dispatch(handleGoogleCallback(code))
        .unwrap()
        .then((result) => {
          console.log("User data after successful Google login:", result);
          checkUserInfo(result.user);
        })
        .catch((error) => {
          console.error('Error during Google login:', error);
          navigate('/login', { state: { error: 'Failed to login with Google' } });
        });
    }
  }, [dispatch, location, navigate]);

  const checkUserInfo = (user) => {
    if (!user.username || !user.email || !user.direccion || !user.telefono) {
      navigate('/PersonalData');
    } else {
      navigate('/');
    }
  };

  return <div>Processing Google login...</div>;
};

export default GoogleCallback;